import { graphql, Link } from "gatsby";
import React from "react";
import Layout from "~components/layouts/Layout";
import P from "~components/typography/P";
import KeySellingpoints from "~components/sections/landingpages/KeySellingpoints";
import LandingpageHeader from "~components/sections/landingpages/LandingpageHeader";
import About from "~components/sections/landingpages/About";
import FeatureList from "~components/sections/landingpages/FeatureList";
import SchemaProduct from "~components/layouts/schemas/SchemaProduct";

export default function Page({ data, location }) {
	const keyword = "Webdesign Coaches";
	const title = "Webdesign Coaches: Unser Full Service Angebot";
	const description = "Webdesign für Coaches gesucht? Du hast Deinen Webdesigner gefunden! Wir bauen Deine Website.";

	return (
		<Layout
			location={location}
			title={title}
			desc={description}
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.headerImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.headerImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.headerImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.headerImage.twitterAndProduct.gatsbyImageData.images.fallback.src}`}
		>
			<SchemaProduct
				name={keyword}
				image={data.headerImage.twitterAndProduct.gatsbyImageData.images.fallback.src}
				description={description}
				aggregateOffer
				lowPrice="500"
				highPrice="5000"
				url={`${data.site.siteMetadata.siteUrl}${location.pathname}`}
			/>
			<LandingpageHeader
				headline={{ one: `Webdesign Coaches:`, two: "Unser Full Service Angebot" }}
				image={{ data: data.headerImage.header, alt: `${keyword} - Coach im Portrait` }}
				text={
					<>
						Wir wissen, dass Du keine Zeit für Deine Website hast und Dich gerne auf Dein Coaching konzentrieren
						möchtest. Genau deswegen haben wir ein System entwickelt, bei dem wir regelmäßig Deine Website pflegen und
						dafür sorgen, dass sich die Website stetig verbessert und immer mehr Umsatz abwirft. Wir sehen Websites
						nicht als Produkt, sondern als Full Service.
					</>
				}
				buttonOne={{ text: "Kostenloses Beratungsgespräch vereinbaren", to: `${location.pathname}anfragen/` }}
				pages={[
					{ name: "Leistungen", to: "/leistungen/" },
					{
						name: keyword,
						to: location.pathname,
					},
				]}
			/>

			<KeySellingpoints
				headline="Webdesign für Coaches: Die Website, die Dir hilft"
				intro="Wir haben die digitalen Lösungen um Dir als Coach Zeit und Geld zu sparen. Wir klicken nicht nur Websites zusammen, sondern bauen digitale Lösungen. Kein Bruch im in der Customer Journey und kein Wechsel auf weitere Dienstleiterseiten, das wäre ein Traum, oder? Deine Kunden wollen volldigital in einer Welt (Deiner Website) Deine Coaching-Produkte erwerben und Termine bei Dir buchen! Mit den richtigen Tools und eingebauten Schnittstellen, bauen wir eine Website, die sich von einer reinen Informationsplattform abgrenzt und so direkt Dich im Arbeitsleben unterstützt. Wir haben eine Reihe an Erfahrung mit folgenden Lösungen, passend zu Deinem Coaching Dasein:"
				sellingponts={[
					{
						headline: "Mitgliederbereich",
						text: [
							"Egal, ob als kostenpflichtige Lösung, oder als kostenlose Variante zu Deinen Live-Coachings. Mit einem Mitgliederbereich bietest Du eine Lösung zugeschnitten auf Deine Kunden an. Die Digitalisierung schreitet immer weiter voran und Deine Kunden verlangen von Dir dementsprechend auch eine digitale Lösung - sie sind es nicht anders gewohnt. Mit einem eigenen flexiblen Log-in-System hast Du so einen soliden Grundstein, um gezielt Deine Premium-Kunden anzusprechen. Das System wird individuell entwickelt und bedient sich nicht fertiger Lösungen. Solltest Du in der Zukunft auch eine APP planen, so kann diese über dasselbe Mitglieder-System laufen. Du erhältst eine nahtlose Integration von Website und App.",
						],
						image: data.imageMitglieder,
						alt: `${keyword} - Mitgliederbereich Dashboard`,
					},
					{
						headline: "Online Terminkalender",
						text: [
							"Terminabsprachen können kompliziert werden. Durch einen online Terminkalender, der sich mit Deinem vorhandenem Google Kalender synchronisiert und nur Tage freigibt, die Du zur Verfügung stellst, ist eine große Hilfe.",
						],
						image: data.imageTermin,
						alt: `${keyword} -  Online Terminkalender`,
					},
					{
						headline: "Online-Shop für digitale Medien",
						text: [
							<>
								Du bietest neben Deinen Seminaren auch digitale Produkte wie Hörbücher, E-Books oder Audiofiles an? Mit
								einem integrierten und vollautomatischen Online-Shop kannst Du diese Produkte gezielt auf Deiner Website
								verkaufen. Als Shopify Partner verfügen wir über die Mittel die Cloud-Shopsoftware, als Headless-Shop
								einzubauen. So verweben wir die Shop-Experience eng mit der Website und der Kunde wird nicht auf einen
								lahmen externen Shop weitergeleitet. Auch jegliche Zahlungsabwicklungen und Rechnungsstellungen werden
								bei Bedarf mit Deiner vorhandenen Buchhaltung verknüpft. Informieren und Shoppen auf einer nahtlosen
								integrierten Website, das ist unsere Lösung. Besuche gerne den{" "}
								<a
									href="https://www.entspannungshelden.de/shop/"
									className="underline"
									target="_blank"
									rel="noopener noreferrer"
								>
									Online-Shop
								</a>{" "}
								der Entspannungshelden, der genau nach diesem Prinzip entwickelt wurde.
							</>,
						],
						image: data.imageShop,
						alt: `${keyword} - Nahaufnahme eines Terminkalenders`,
					},
					{
						headline: "Visual Landingpage-Editor",
						text: [
							"Werbekampagnen sind essenziell für jeden Coach. Damit die Werbung auch einen Erfolg hat, benötigst Du eine Landingpage, die optimiert und vielversprechend ist. Mit einem visuellen Editor kannst Du oder wir spielend leicht eine Landingpage nach Deinen Vorstellungen erstellen. Wir bauen grundsolide Bausteine auf, die Deiner Business-DNA entsprechend und verkaufsfördernd zugleich sind. So sieht die Landingpage nicht nur geil aus, sondern verkauft auch noch zusätzlich. ",
						],
						image: data.imageLandingpage,
						alt: `${keyword} - Landingpage-Editor im Browser`,
					},
					{
						headline: "Sichtbarkeit",
						text: [
							"Du bist neu auf dem Markt und möchtest Dich selbständig machen? Du hast schon Kunden, aber Du möchtest noch größer werden? Eine schöne Seite reicht doch, oder?",
							"Wenn Du erfolgreich in Deiner Stadt werben willst, dürfen die digitalen Wege, wie eine erfolgreiche Website, nicht fehlen. Die Website ist die Eingangstür zu Deinem Unternehmen. Bevor der Kunde zu Dir kommt, besucht er Dich online. Damit aber Deine potenziellen Kunden Dich auch bei Google und Co. finden musst Du gewisse Maßnahmen treffen. In diesem Fall reicht eine schöne Website nicht.",
							<P>
								Mit intelligentem Design und ausgefeilter Technologie erschaffen wir Websites, die nachhaltig Deine
								Prozesse optimieren und{" "}
								<strong>
									Kunden <u>ohne</u> teure Werbeanzeigen beschaffen
								</strong>
								. Mit der SEO Premium Agentur aus Stuttgart arbeiten wir mit einem Partner zusammen, der sich auf Local{" "}
								<Link to="/leistungen/seo-agentur-kassel/" className="underline">
									SEO
								</Link>{" "}
								konzentriert. Local SEO beschreibt dabei die Suchmaschinenoptimierung Deiner Website für Suchbegriffe,
								die besonders in Deiner Stadt gesucht werden. So ist auch diese Seite eine Maßnahme einer solchen
								Optimierung.
							</P>,
						],
						image: data.imageSeo,
						alt: `${keyword} - Kunden auf der Suche nach einem Dienstleister`,
					},
				]}
			/>

			<FeatureList
				headline={`${keyword}: Unser Leistungsspektrum`}
				text={`Uns sind ganzheitliche Lösungen für unsere Kunden über aus wichtig. Daher bieten wir auch für den Bereich ${keyword} eine ganzheitliche Lösung für Deine Website. Ein einfacher Auftrag genügt und wir kümmern uns um die erfolgreiche Projektumsetzung und darüber hinaus.`}
				features={data.site.siteMetadata.globalFeautureList.webdesign}
			/>

			<About link={`${location.pathname}anfragen/`} keyword={keyword} />
		</Layout>
	);
}

export const query = graphql`
	{
		headerImage: file(relativePath: { eq: "pages/leistungen/webdesign-coaches/webdesign-coaches_header.jpg" }) {
			header: childImageSharp {
				gatsbyImageData
			}
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitterAndProduct: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		imageMitglieder: file(
			relativePath: { eq: "pages/leistungen/webdesign-coaches/webdesign-coaches_mitgliedger-bereich.png" }
		) {
			childImageSharp {
				gatsbyImageData
			}
		}
		imageTermin: file(relativePath: { eq: "pages/leistungen/webdesign-coaches/webdesign-coaches_termin.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}

		imageShop: file(relativePath: { eq: "pages/leistungen/webdesign-coaches/webdesign-coaches_online-shop.png" }) {
			childImageSharp {
				gatsbyImageData
			}
		}

		imageLandingpage: file(
			relativePath: { eq: "pages/leistungen/webdesign-coaches/webdesign-coaches_landingpage-generator.jpg" }
		) {
			childImageSharp {
				gatsbyImageData
			}
		}

		imageSeo: file(relativePath: { eq: "pages/leistungen/webdesign-coaches/webdesign-coaches_local-seo.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}

		site {
			siteMetadata {
				siteUrl
				globalFeautureList {
					webdesign {
						headline
						text
					}
				}
			}
		}
	}
`;
